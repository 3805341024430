import React, { useEffect, useState } from "react";
import { Button, Input, Label, UncontrolledAccordion, AccordionBody, AccordionHeader, AccordionItem, UncontrolledTooltip, Row, Col } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { addOrderDoc, confirmSigning, deleteAdditionalDocs } from "helpers/backendHelper";
import Order from "model/order";
import { showError, showSuccess } from "helpers/utilHelper";
import { doOrderInkSignDocsCleanup, getOrder, getOrderInkSignDocs } from "store/actions";
import OrderNotary from "model/orderNotary";
import { BrowserView, isMobile, MobileView } from "react-device-detect";
import SectionAdditionalDocs from "./Section/AdditionalDocs";
import SectionNotaryDocs from "./Section/NotaryDocs";
import OrderDoc from "model/orderDoc";
import addPhotoIcon from "assets/images/alert-informations-section/add-photo-icon.svg";
import takePhotoIcon from "assets/images/take-picture-btn.svg";
import loadingBarIcon from "assets/images/alert-informations-section/loading-bar-icon.svg";
import noPdfIcon from "assets/images/alert-informations-section/no-pdf-icon.svg";
import noPictureIcon from "assets/images/alert-informations-section/no-picture-icon.svg";
import networkSignalIcon from "assets/images/network-signal.svg";
import AlertInformationsSection from "components/Shared/AlertInformationsSection";
import Confirmation from "components/Shared/Confirmation";
import InfoMessage from "components/Shared/InfoMessage";
import { UNABLE_INSERT_DOC_DUE_TO_UNIQUE_KEY } from "helpers/errorHelper";

const notaryAlerts = {
  title: 'A few things you should know',
  alerts: [
    {
      icon: {
        desktop: addPhotoIcon,
        mobile: takePhotoIcon,
      },
      description: "<div>Upload the <strong>Affidavit of Identity</strong> and <strong>Driver License</strong> under the <strong>Upload Required Documents</strong> section below.</div>",
    },
    {
      icon: {
        desktop: noPictureIcon,
      },
      description: "<div>Select Add Photo then take/upload a photo of each page of the document.</div>",
    },
    {
      icon: {
        desktop: loadingBarIcon,
      },
      description: "<div>Please make sure all required <strong>items are uploaded before exiting.</strong></div>",
    },
    {
      icon: {
        desktop: noPdfIcon,
      },
      description: "<div>PDFs are not accepted for required documents. Use the <strong>Add Additional Documents</strong> toggle to upload <strong>PDFs</strong> for optional documents.</div>",
    },
  ]
}

// if a doc has num of pages set to 0, it means that it was uploaded as an additional doc
const isAdditionalDoc = doc => doc.numOfPages === 0;

const StepDuringSigning = () => {

  let { id } = useParams();
  id = parseInt(id);

  const dispatch = useDispatch();

  /********** STATE **********/

  const order = useSelector(state => state.Order.Single.order);

  const { docs } = useSelector(state => state.Order.InkSignDocs);

  const [open, setOpen] = useState(true);

  // whether any page uploads are still in progress
  const [hasInProgressUploads, setHasInProgressUploads] = useState(false);

  const hasAllPages = doc => doc.numOfPages === Object.keys(doc.pages || {}).length;

  const hasAtLeastOnePage = doc => Object.keys(doc.pages || {}).length > 0

  const isUploadComplete = !!docs && docs.every(doc => {
    // docs captured as pdf are considered complete
    return doc.isCapturedAsPdf
      // docs that are neither standard nor additional are optional
      || (!doc.isStdDoc && !isAdditionalDoc(doc))
      // standard docs must be uploaded completely
      || (doc.isStdDoc && hasAllPages(doc))
      // additional docs must have at least one page uploaded
      || (isAdditionalDoc(doc) && hasAtLeastOnePage(doc));
  });

  const isPageRejected = (doc) => !!doc?.pages && Object.keys(doc.pages).some((page, index) => doc.pages[index + 1]?.status == OrderDoc.PAGE_STATUS_REJECTED);

  const areDocsRejected = !!docs && docs.some(doc => isPageRejected(doc));

  const isReadOnly = () => order.status == Order.STATUS_CANCELLED || order.orderNotaryStatus == OrderNotary.STATUS_ORDER_COMPLETE;

  const isNotCurrentStep = order.orderNotaryStatus !== OrderNotary.STATUS_DOCS_PRINTED || isReadOnly();

  const additionalDocs = docs?.filter(isAdditionalDoc);
  // std docs are required
  // (make sure to exclude additional docs as they are listed separately)
  const requiredDocs = docs?.filter(doc => doc.numOfPages !== 0 && doc.isStdDoc);
  // non-std docs are optional
  // (make sure to exclude additional docs as they are listed separately)
  const optionalDocs = docs?.filter(doc => doc.numOfPages !== 0 && !doc.isStdDoc);
  const hasAdditionalDocs = additionalDocs?.length > 0;
  // if order has additional docs uploaded, the add doc form is open by default
  const [addDocFormIsOpen, setAddDocFormIsOpen] = useState(order?.notaryMustUploadDocs || hasAdditionalDocs);
  const [newDocName, setNewDocName] = useState();
  const [collectedCustomerPayment, setCollectedCustomerPayment] = useState(false);
  const [checkPaymentType, setCheckPaymentType] = useState('');
  const [notaryCustomerCheckType, setNotaryCustomerCheckType] = useState('');

  const [mustPreviewDocsModal, setMustPreviewDocsModal] = useState(false);

  const isPaymentForCustomerValid = (collectedCustomerPayment && (!checkPaymentType || !notaryCustomerCheckType))

  const mustPreviewDocs = order?.notaryMustUploadDocs;

  const isStepComplete = order.orderNotaryStatus >= OrderNotary.STATUS_DOCS_UPLOADED;

  /********** EFFECTS **********/

  useEffect(() => {
    refreshInkDocs();
    return () => {
      dispatch(doOrderInkSignDocsCleanup());
    }
  }, []);

  // runs anytime docs change, in order to update the local state
  useEffect(() => {
    // we should automatically toggle only when switch is currently off
    if (!addDocFormIsOpen) {
      setAddDocFormIsOpen(hasAdditionalDocs);
    }
  }, [docs]);

  /********** HANDLERS **********/
  const handleConfirmStep = () => {
    // Show a pop'up message if the dealer requierd Preview for the docs and
    // no item is added on Additional Documents section
    if (mustPreviewDocs && !hasAdditionalDocs && !!optionalDocs?.length) {
      setMustPreviewDocsModal(true);
    } else confirm();
  }

  const confirm = async () => {
    try {
      await confirmSigning(order.orderNotaryId, {
        collectedCustomerPayment,
        checkPaymentType,
        notaryCustomerCheckType,
      });
      showSuccess("Step completed");
      refreshOrder();
    } catch (err) {
      showError("Unable to complete step");
    }
  };

  const toggleAdditionalDocs = async event => {
    const newValue = event.target.checked;
    setAddDocFormIsOpen(newValue);
    setNewDocName("");
    // if user switches off additional documents, all the additional documents that have been added must be deleted
    if (hasAdditionalDocs && newValue === false) {
      try {
        await deleteAdditionalDocs(order.orderNotaryId);
        refreshInkDocs();
      } catch (err) {
        showError("Unable to complete step");
      }
    }
  };

  const addAdditionalDoc = async () => {
    try {
      await addOrderDoc({
        docType: OrderDoc.TYPE_INK_SIGNED,
        docName: newDocName,
        numOfPages: 0,
        isNotarizationRequired: false,
      }, id);
      showSuccess("Additional document added");
      refreshInkDocs();
      setNewDocName("");
    } catch (err) {

      // default error message
      let errMessage = 'Unable to add document.';

      // this document name already exist
      if (err.code == UNABLE_INSERT_DOC_DUE_TO_UNIQUE_KEY) {
        errMessage = 'There is already a document with this name.';
      }

      showError(errMessage);
    }
  };

  const handleCheckCollectPayment = event => {
    setCollectedCustomerPayment(event.target.checked);
  };

  const handleFormOfPayment = event => {
    setCheckPaymentType(event.target.value);
  };

  const handleNotaryCustomerCheckType = event => {
    setNotaryCustomerCheckType(event.target.value);
  };

  const toggle = id => {
    if (open == id) {
      setOpen();
    } else {
      setOpen(id);
    }
  };

  /********** OTHER **********/

  const refreshInkDocs = () => {
    dispatch(getOrderInkSignDocs(id));
  };

  const refreshOrder = () => dispatch(getOrder(id));

  const getWhyUnableToContinue = () => {
    if (isNotCurrentStep) {
      return 'Please complete all previous steps';
    }
    if (!isUploadComplete) {
      return 'Please upload all required documents';
    }
    if (hasInProgressUploads) {
      return 'Please wait for all uploads to complete';
    }
    if (isPaymentForCustomerValid) {
      return 'Please declare what you collected';
    }
    if (areDocsRejected) {
      return 'Cannot confirm this step while documents are rejected.';
    }
  }

  return (
    <React.Fragment>
      <div className="mt-1 mb-1">
        <AlertInformationsSection alertsList={notaryAlerts.alerts} title={notaryAlerts.title} notaryMustUploadDocs={mustPreviewDocs} />
        <MobileView>
          <div className="divider-faded divider-sm mb-3" />
          <h6 className="mb-3">Upload documents</h6>
        </MobileView>
      </div>

      {/* Required Documents Section */}
      <UncontrolledAccordion className="docs-required-accordion" open={open} toggle={toggle}
        defaultOpen={['docs-required']} stayOpen>
        <AccordionItem key='docs-required'>
          <AccordionHeader
            targetId='docs-required'
            className="docs-required-header"
          >
            <h6 className="accordion-title m-0">{isMobile ? 'Required' : 'Upload Required Documents'}</h6>
          </AccordionHeader>
          <AccordionBody className="docs-required-body" accordionId='docs-required'>
            <div className={isMobile ? 'px-2' : undefined}><InfoMessage className="my-3" description={OrderNotary.MESSAGE_TIME_SAVE_TIP} /></div>
            <SectionNotaryDocs order={order} docs={requiredDocs} refreshHandler={refreshInkDocs} disabled={isNotCurrentStep} setHasInProgressUploads={setHasInProgressUploads} />
          </AccordionBody>
        </AccordionItem>
      </UncontrolledAccordion>

      {/* Additional Documents Section */}
      <div className="additional-documents-mobile-wrapper">
        <div className={`doc-item mb-3 ${addDocFormIsOpen ? 'form-open' : ''}`}>
          <Label className="font-size-14 me-5 mb-0">Add additional documents</Label>
          <div className="form-check form-switch form-switch-lg ms-3">
            <Input
              type="checkbox"
              key={addDocFormIsOpen}
              className="form-check-input"
              id="addDocFormIsOpenSwitch"
              name="addDocFormIsOpen"
              onChange={toggleAdditionalDocs}
              defaultChecked={addDocFormIsOpen}
              disabled={isNotCurrentStep}
            />
            <Label className="form-check-label" htmlFor="addDocFormIsOpenSwitch" />
          </div>
          {!!mustPreviewDocs && <InfoMessage className="mt-3" description={OrderNotary.MESSAGE_ADD_ADDITIONAL_DOCS} />}
        </div>
        {addDocFormIsOpen && <>
          {hasAdditionalDocs && <SectionAdditionalDocs docs={additionalDocs} refreshHandler={refreshInkDocs} disabled={isNotCurrentStep} setHasInProgressUploads={setHasInProgressUploads} />}
          <div className="mobile-add-doc mb-3">
            <Label className="mobile-add-doc-title">Document Name *</Label>
            <div className="mobile-add-doc-wrapper d-flex align-items-center">
              <div className="mobile-input-wrapper flex-grow-1 me-3">
                <Input
                  type="text"
                  placeholder="ex. Notary Checklist, Dealer Checklist"
                  onChange={e => setNewDocName(e.target.value)}
                  value={newDocName}
                  disabled={isNotCurrentStep}
                />
              </div>
              <BrowserView>
                <Button
                  outline
                  color="primary"
                  className="px-4"
                  onClick={addAdditionalDoc}
                  disabled={isNotCurrentStep || !newDocName}
                >
                  + Add document
                </Button>
              </BrowserView>
              <MobileView>
                <Button
                  outline
                  color="primary"
                  className="square-icon-btn"
                  onClick={addAdditionalDoc}
                  disabled={isNotCurrentStep || !newDocName}
                >
                  <i className="mdi mdi-plus" /> Add document
                </Button>
              </MobileView>
            </div>
          </div>
        </>}
      </div>

      {order.itemsToCollect && <div className="mb-3 mt-4">
        <div className="divider-faded divider-sm" />
        <p className="collect-form-title mt-2">Make sure you collected:</p>
        <div className="bordered-section">
          {order?.itemsToCollect.split(",").map(doc => <li key={doc}>{Order.getItemToCollectName(doc)}</li>)}
        </div>
      </div>}

      {order.additionalItemsToCollect && <div className="mb-3 mt-4">
        <div className="divider-faded divider-sm" />
        <p className="collect-form-title mt-2">Additional Items to Collect:</p>
        <p className="bordered-section">{order.additionalItemsToCollect}</p>
        <div className="divider-faded divider-sm" />
      </div>}

      {!!mustPreviewDocs && <div className="mb-3 mt-4">
        <p className="collect-form-title mt-2">Documents to be uploaded:</p>
        <div className="bordered-section">{docs?.map((entry, index) => <li key={index}>{entry.name}</li>)}</div>
        <div className="divider-faded divider-sm" />
      </div>}

      <div className="mb-3 mt-4">
        <p className="d-flex align-items-start"><img className="me-2" src={networkSignalIcon} /> If you have a hard time during the appointment with the internet connection, please collect all the documents,  signatures and information needed and complete this step as soon as you reach Wi-Fi or steady internet connection.</p>

        {/* Desktop Collect Payment - checkbox */}
        <BrowserView>
          <div className="form-check my-2 mt-4">
            <div>
              <Input
                type="checkbox"
                name="collectedCustomerPayment"
                id="collectedCustomerPayment"
                className="form-check-input-lg me-2"
                checked={collectedCustomerPayment}
                onChange={handleCheckCollectPayment}
                disabled={isNotCurrentStep}
              />
              <Label htmlFor="collectedCustomerPayment">Did you collect Payment from the customer</Label>
            </div>
          </div>
          {collectedCustomerPayment && <>
            <div className="form-check-area">
              <p className="collect-form-title">Form of Payment</p>
              <div className="d-flex">
                <div>
                  <Input
                    type="radio"
                    name="personal"
                    id="personal"
                    value="personal"
                    className="form-check-input me-2"
                    checked={checkPaymentType === 'personal'}
                    onChange={handleFormOfPayment}
                    disabled={isNotCurrentStep}
                  />
                  <Label htmlFor="personal">Personal Check</Label>
                </div>
                <div className="ms-3">
                  <Input
                    type="radio"
                    name="cashier"
                    id="cashier"
                    value="cashier"
                    className="form-check-input me-2"
                    checked={checkPaymentType === 'cashier'}
                    onChange={handleFormOfPayment}
                    disabled={isNotCurrentStep}
                  />
                  <Label htmlFor="cashier">{"Cashier's Check"}</Label>
                </div>
              </div>
              <p className="collect-form-title mt-2">The check was</p>
              <div className="d-flex">
                <div>
                  <Input
                    type="radio"
                    name="checkSecureToDocuments"
                    value="checkSecure"
                    id="checkSecureToDocuments"
                    className="form-check-input me-2"
                    checked={notaryCustomerCheckType === 'checkSecure'}
                    onChange={handleNotaryCustomerCheckType}
                    disabled={isNotCurrentStep}
                  />
                  <Label htmlFor="checkSecureToDocuments">Secured to documents</Label>
                </div>
                <div className="ms-3">
                  <Input
                    type="radio"
                    name="checkEnclosedInMailing"
                    value="checkEnclosed"
                    id="checkEnclosedInMailingEnvelope"
                    className="form-check-input me-2"
                    checked={notaryCustomerCheckType === 'checkEnclosed'}
                    onChange={handleNotaryCustomerCheckType}
                    disabled={isNotCurrentStep}
                  />
                  <Label htmlFor="checkEnclosedInMailingEnvelope">Enclosed in a Mailing Envelope</Label>
                </div>
              </div>
            </div>
          </>}
        </BrowserView>

        {/* Mobile Collect Payment - switch */}
        <MobileView>
          <div className="divider-faded divider-sm my-4" />
          <div className="mobile-collect-payment-header mb-3">
            <Label className="me-5 mb-0">Did you collect Payment from the customer</Label>
            <div className="form-check form-switch form-switch-lg">
              <Input
                type="checkbox"
                name="collectedCustomerPayment"
                id="collectedCustomerPayment"
                className="form-check-input-lg me-2"
                checked={collectedCustomerPayment}
                onChange={handleCheckCollectPayment}
                disabled={isNotCurrentStep}
              />
              <Label className="form-check-label" htmlFor="collectedCustomerPayment" />
            </div>
          </div>

          {collectedCustomerPayment && (<>
            <div className="form-check-area mobile-collect-payment-body">
              <p className="collect-form-title">Form of Payment</p>
              <div className="d-flex">
                <label
                  className={`form-check-row ${checkPaymentType === 'personal' ? 'checked' : ''}`}
                  htmlFor="personal"
                >
                  <Input
                    type="radio"
                    name="personal"
                    id="personal"
                    value="personal"
                    className="me-2"
                    checked={checkPaymentType === 'personal'}
                    onChange={handleFormOfPayment}
                    disabled={isNotCurrentStep}
                  />
                  <span className="form-label">Personal Check</span>
                </label>
                <label
                  className={`form-check-row ${checkPaymentType === 'cashier' ? 'checked' : ''}`}
                  htmlFor="cashier"
                >
                  <Input
                    type="radio"
                    name="cashier"
                    id="cashier"
                    value="cashier"
                    className="me-2"
                    checked={checkPaymentType === 'cashier'}
                    onChange={handleFormOfPayment}
                    disabled={isNotCurrentStep}
                  />
                  <span className="form-label">{"Cashier's Check"}</span>
                </label>
              </div>
              <div className="divider-faded divider-sm" />
              <p className="collect-form-title mt-2">The check was</p>
              <div className="d-flex">
                <label
                  className={`form-check-row ${notaryCustomerCheckType === 'checkSecure' ? 'checked' : ''}`}
                  htmlFor="checkSecureToDocuments"
                >
                  <Input
                    type="radio"
                    name="checkSecureToDocuments"
                    value="checkSecure"
                    id="checkSecureToDocuments"
                    className="me-2"
                    checked={notaryCustomerCheckType === 'checkSecure'}
                    onChange={handleNotaryCustomerCheckType}
                    disabled={isNotCurrentStep}
                  />
                  <span className="form-label">Secured to documents</span>
                </label>
                <label
                  className={`form-check-row ${notaryCustomerCheckType === 'checkEnclosed' ? 'checked' : ''}`}
                  htmlFor="checkEnclosedInMailingEnvelope"
                >
                  <Input
                    type="radio"
                    name="checkEnclosedInMailing"
                    value="checkEnclosed"
                    id="checkEnclosedInMailingEnvelope"
                    className="me-2"
                    checked={notaryCustomerCheckType === 'checkEnclosed'}
                    onChange={handleNotaryCustomerCheckType}
                    disabled={isNotCurrentStep}
                  />
                  <span className="form-label">Enclosed in a Mailing Envelope</span>
                </label>
              </div>
            </div>
          </>
          )}
        </MobileView>
      </div>

      {!isStepComplete &&
        <div className="d-flex justify-content-end">
          <div className="mav-card-action-btn" id="during-signing-continue-btn">
            <Button
              color="primary"
              className="w-100"
              onClick={handleConfirmStep}
              disabled={isNotCurrentStep || !isUploadComplete || isPaymentForCustomerValid || hasInProgressUploads || areDocsRejected}
            >
              Confirm
            </Button>
          </div>
          {!!getWhyUnableToContinue() && <UncontrolledTooltip placement="top" target="during-signing-continue-btn">{getWhyUnableToContinue()}</UncontrolledTooltip>}
        </div>
      }
      {/* Must Preview Docs Modal */}
      {mustPreviewDocsModal && <Confirmation
        style={{ backgroundColor: 'white', minWidth: 350, paddingBottom: 35 }}
        cancelBtnText="Close"
        onCancel={() => setMustPreviewDocsModal(false)}
        closeOnClickOutside={false}
        showConfirm={false}
        reverseButtons={false}
      >
        <div className="d-flex justify-content-center">
          <Col sm={10}>
            <span style={{ color: '#556EE6', fontSize: 15, textAlign: 'left' }}>Please upload all signed dealer documents in the <strong>Add additional documents</strong> section.</span>
          </Col>
        </div>
      </Confirmation>}
    </React.Fragment >
  )
};

export default StepDuringSigning;