import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Tr, Th, Td } from "react-super-responsive-table"
import { useDispatch, useSelector } from "react-redux";
import classnames from "classnames";
import { getBeUrl, showError, showSuccess } from "helpers/utilHelper";
import { formatTimestamp, formats } from "helpers/dateHelper";
import Credential from "model/notaryCredential";
import { timestamp } from "helpers/dateHelper";
import pdfIcon from 'assets/images/pdf-icon.svg';

const CredentialDoc = props => {

  const { id, num, refreshListHandler } = props;

  // redux hook that dispatches actions
  const dispatch = useDispatch();

  /********** STATE **********/

  const credential = useSelector(state => state.Credential.List.credentials[id]);

  /********** EFFECTS **********/

  // runs whenever the credential doc is deleted
  useEffect(() => {
    if (credential.isDeleted === true) {
      showSuccess(`Document "${Credential.getFileName(credential.type)}" has been deleted`);
      refreshListHandler();
    } else if (credential.isDeleted === false) {
      showError("Unable to delete document");
    }
  }, [credential.isDeleted]);

  /********** EVENT HANDLERS **********/

  return <React.Fragment>
    {credential && <Tr className={classnames({ deleting: credential.isDeleteInProgress })}>
      <Th className="co-name">{num}</Th>
      <Td>{credential.typeName}</Td>
      <Td>
        <a href={getBeUrl(`notary-credential/${credential.id}/render`)} target="_blank" rel="noreferrer">
          <img src={pdfIcon} /> {Credential.getFileName(credential.type)}
        </a>
      </Td>
      <Td>{getCredentialStatus(credential.expirationTs)}</Td>
      <Td>
        {credential.expirationTs
          ?
          <p className={credential.expirationTs < timestamp() ? "text-danger" : ""}>
            {formatTimestamp(credential.expirationTs, formats.DATE_PT_FORMAT)}
          </p>
          :
          '--'
        }
      </Td>
      <Td>{formatTimestamp(credential.createdTs, formats.DATE_PT_FORMAT)}</Td>
    </Tr>}
  </React.Fragment>
}

const getCredentialStatus = expirationTs => {
  if (!expirationTs) return "Active";
  return expirationTs < timestamp() ? "Expired" : "Active";
}

CredentialDoc.propTypes = {
  id: PropTypes.number.isRequired,
  num: PropTypes.number.isRequired,
  refreshListHandler: PropTypes.func.isRequired,
};

export default CredentialDoc;
