import React from "react";
import { Col, Row } from "reactstrap";
import Order from "model/order";
import { formatTimestamp, formats } from "helpers/dateHelper";
import addressIcon from "assets/images/address-icon.svg";
import documentsIcon from "assets/images/documents-icon.svg";
import trackingIcon from "assets/images/tracking-number-icon.svg";
import shipperIcon from "assets/images/shipper-box-icon.svg";
import contactIcon from "assets/images/contact-icon.svg";
import classnames from "classnames";
import { route, routes } from "helpers/routeHelper";
import Message from "model/message";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

const StepDocsDeliveredDesktop = ({ id, notary, order, isDeliveryOptionUpload, isNotCurrentStep, handleSetShippingModal }) => {

  return (
    <React.Fragment>
      <h6 className="accordion-title my-2">Documents Delivery Information</h6>
      <Row>
        <Col xl={3} xs={12}>
          <div className="d-flex align-items-start mt-2">
            <img src={addressIcon} alt="address-icon" />
            <div>
              <h6 className="accordion-title ms-2 pt-1">{"Notary's Address"}</h6>
              <div className="font-size-11 ms-2 mt-2">{notary.shippingAddress || '--'}</div>
              <div className="font-size-11 ms-2">
                City: {notary.shippingCity || '--'}
              </div>
              <div className="font-size-11 ms-2">
                Zip code: {notary.shippingZip || '--'}
              </div>
            </div>
          </div>
        </Col>
        {!isDeliveryOptionUpload && <><Col xl={3} xs={12}>
          <div className="d-flex align-items-start mt-2">
            <img src={trackingIcon} alt="address-icon" />
            <div>
              <h6 className="accordion-title ms-2 pt-1">Tracking Number</h6>
              <div onClick={handleSetShippingModal}
                className={classnames("cursor-pointer font-size-11 ms-2 mt-2", { "tracking-number": !!order.shippingPackageAwb })}>
                {<span className='package-number'>
                  {order.shippingPackageAwb}</span> || '--'}
              </div>
            </div>
          </div>
        </Col>
          <Col xl={3} xs={12}>
            <div className="d-flex align-items-start mt-2">
              <img src={shipperIcon} alt="address-icon" />
              <div>
                <h6 className="accordion-title ms-2 pt-1">Shipper</h6>
                <div className="font-size-11 ms-2 mt-2">{Order.getShippingCompanyName(order.shippingCompany)}</div>
              </div>
            </div>
          </Col>
          <Col xl={3} xs={12}>
            <div className="d-flex align-items-start mt-2">
              <img src={documentsIcon} alt="address-icon" />
              <div>
                <h6 className="accordion-title ms-2 pt-1">Expected Documents Receival Date</h6>
                <div className="font-size-11 ms-2 mt-2">{formatTimestamp((order.docsSentToNotaryTs + 24 * 60 * 60), formats.FULL_MONTH_DATE) || '--'}</div>
              </div>
            </div>
          </Col></>}
      </Row>
      {!isDeliveryOptionUpload && <>
        <div className="divider-faded divider-sm" />
        <Link
          to={route(routes.view_order_messages, [id, Message.CHANNEL_SCHEDULER_NOTARY])}
          className={classnames("font-size-13 text-dark fw-medium", { "pe-none": isNotCurrentStep })}
        >
          <img src={contactIcon} alt="contact-icon" className="rounded-circle me-3" />
          I Did Not Receive The Documents Yet
        </Link>
      </>}

      <div className="divider-faded divider-sm" />

      <h6>Action:</h6>
      <ul>
        <li>
          Please inform the customer about the items you will be collecting at the signing.
        </li>
      </ul>
    </React.Fragment>
  )
};

StepDocsDeliveredDesktop.propTypes = {
  id: PropTypes.number,
  notary: PropTypes.object,
  order: PropTypes.object,
  isDeliveryOptionUpload: PropTypes.bool,
  isNotCurrentStep: PropTypes.bool,
  handleSetShippingModal: PropTypes.func,
};

export default StepDocsDeliveredDesktop;