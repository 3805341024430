import React from "react";
import PropTypes from 'prop-types';
import rushOrderServiceIcon from 'assets/images/rush-order-icon.svg';
import docDeliveryShippingIcon from 'assets/images/delivery-shipping.svg';
import docDeliveryUploadIcon from 'assets/images/delivery-upload.svg';
import mustUploadDocsIcon from 'assets/images/notary-must-upload-docs.svg';
import aoiThumbprintIcon from 'assets/images/aoi-image.svg';
import Order from "model/order";
import { Alert } from "reactstrap";
import locationChangeDenyIcon from 'assets/images/location-change.svg';

const OrderServicesSection = ({ order, className }) => {
  const isAoiWithoutThumbprint = !order.isAoiThumbprintRequired;
  const isNotAllowedChangeLocation = order.canChangeSigningLocation === false;

  return <div className={`order-services ${className}`}>
    {isNotAllowedChangeLocation && <Alert color="danger" className="fade show mb-2 no-opacity bring-forward">
      <div className="d-flex align-items-start">
        <img className="mt-1" src={locationChangeDenyIcon} />
        <span className="ms-3">Location changes are <strong>NOT</strong> permitted on this order. This signing MUST be completed inside, at the location provided below.</span>
      </div>
    </Alert>}
    {isAoiWithoutThumbprint && <div className="aoi-thumbprint-banner d-flex font-size-12 mb-2">
      <img src={aoiThumbprintIcon} />
      <span className="ms-2">This dealership group has <strong>opted-OUT of the thumbprint collection</strong>. The Affidavit will need to be completed <strong>less the thumbprint</strong>. The Affidavit should be printed for each order, <strong>on step 3</strong>.</span>
    </div>
    }
    {(!!order.isRushRequired || !!order.orderRushRequired) &&
      <div className="order-services-row mb-2">
        <img className="rush-icon" src={rushOrderServiceIcon} />
        <span className="ms-3">Rushed Order, expected to sign within 24 hours.</span>
      </div>
    }
    {(order.docDeliveryOption == Order.DOC_DELIVERY_OPTION_UPLOAD || order.orderDocDeliveryOption == Order.DOC_DELIVERY_OPTION_UPLOAD) &&
      <div className="order-services-row mb-2">
        <img src={docDeliveryUploadIcon} />
        <span className="ms-3">Order with uploaded docs. Print before signing.</span>
      </div>
    }
    {(order.docDeliveryOption == Order.DOC_DELIVERY_OPTION_SHIPPING || order.orderDocDeliveryOption == Order.DOC_DELIVERY_OPTION_SHIPPING) &&
      <div className="order-services-row mb-2">
        <img src={docDeliveryShippingIcon} />
        <span className="ms-3">Order with shipped documents. Docs will be delivered to you.</span>
      </div>
    }
    {(!!order.notaryMustUploadDocs || !!order.mustUploadDocs) &&
      <div className="order-services-row mb-2">
        <img className="must-upload-icon" src={mustUploadDocsIcon} />
        <span className="ms-3">Preview required for documents. Scan and upload after signing with the customer.</span>
      </div>
    }
  </div>
}

OrderServicesSection.propTypes = {
  order: PropTypes.object,
  className: PropTypes.string,
}

export default OrderServicesSection;