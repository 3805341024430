import React, { useCallback, useEffect, useState } from "react"
import { Container, Row, Col, Button, Alert, Pagination, PaginationItem, PaginationLink } from "reactstrap"
import Breadcrumbs from "components/Common/Breadcrumb2";
import MetaTitle from "components/Shared/MetaTitle";
import AccessDenied from "pages/Error/AccessDenied";
import NotaryBidCard from "./Partial/NotaryBidCard";
import SpinnerChase from "components/Shared/SpinnerChase";
import { useNavigate } from "react-router-dom";
import { perms, useAccess } from "context/access";
import { useDispatch, useSelector } from "react-redux";
import { route, routes } from "helpers/routeHelper";
import { doNotaryBidListCleanup, getNotaryBidList, setNotaryBidFilterToggle } from "store/actions";
import socketEvent from "constants/socketEvent";
import { useSocketOn } from "hooks/socket";
import NotaryBid from "model/notaryBid";
import { map } from "lodash";
import classnames from "classnames";
import biddingIcon from "assets/images/bidding-message-icon.svg";
import { MobileView } from "react-device-detect";

const NotaryBidList = () => {

  // router hook that helps redirect
  const navigate = useNavigate();
  // hook that checks permissions
  const { iAmGranted, iAmNotGranted } = useAccess();
  // hook that dispatches redux actions
  const dispatch = useDispatch();

  /********** STATE **********/

  const { notaryBids, totalCount, isLoadInProgress, notaryBidsError, listParams, filterToggle } = useSelector(state => state.NotaryBid.List);

  const [pagination, setPagination] = useState({
    totalCount,
    page: listParams.page,
    pageSize: listParams.pageSize,
  });

  const { page, pageSize } = pagination;

  const pageCount = Math.ceil(totalCount / pageSize) || 1;

  /********** OTHER **********/

  const refreshNotaryBids = () => dispatch(getNotaryBidList(listParams));

  /********** SOCKET **********/

  const onNotaryBidChanged = useCallback(refreshNotaryBids, [refreshNotaryBids]);

  // listen for changes on messages
  useSocketOn(socketEvent.notaryBidChanged, onNotaryBidChanged);

  /********** EFFECTS **********/

  // runs once on component mount
  useEffect(() => {
    refreshNotaryBids();

    return () => {
      // state cleanup on component unmount
      dispatch(doNotaryBidListCleanup());
    }
  }, [filterToggle]);

  // runs whenever page number or filter toggle changes
  useEffect(() => {
    dispatch(getNotaryBidList({ ...listParams, page }));
  }, [page]);

  // runs whenever total count changes
  useEffect(() => {
    setPagination(current => ({
      ...current,
      totalCount,
    }));
  }, [totalCount]);

  /********** HANDLERS **********/

  const handlePageClick = page => event => {
    // prevent redirecting
    event.preventDefault();

    setPagination(current => ({
      ...current,
      page,
    }));
  };

  const handleToggleFilter = filter => () => {
    dispatch(setNotaryBidFilterToggle(filter));
    // reset local pagination
    setPagination({
      totalCount: 0,
      page: 1,
      pageSize: 9,
    });
  };

  return <React.Fragment>
    {iAmGranted(perms.view_notary_bids) && <React.Fragment>
      <div className="page-content">
        <MetaTitle>My Leads</MetaTitle>
        <Container fluid>
          <div className="d-flex justify-content-between mb-2">
            <Breadcrumbs title="MY LEADS" />
            <div className="d-flex">
              <Button
                color="primary"
                className={classnames("btn-rounded btn-sm filter-btn me-3", { "active bg-primary": filterToggle === NotaryBid.VIRTUAL_STATUS_ALL })}
                onClick={handleToggleFilter(NotaryBid.VIRTUAL_STATUS_ALL)}
              >
                {NotaryBid.getVirtualStatusName(NotaryBid.VIRTUAL_STATUS_ALL)}
              </Button>
              <Button
                color="primary"
                className={classnames("btn-rounded btn-sm filter-btn me-2", { "active bg-primary": filterToggle === NotaryBid.VIRTUAL_STATUS_ACTIVE })}
                onClick={handleToggleFilter(NotaryBid.VIRTUAL_STATUS_ACTIVE)}
              >
                {NotaryBid.getVirtualStatusName(NotaryBid.VIRTUAL_STATUS_ACTIVE)}
              </Button>
            </div>
          </div>

          <MobileView>
            <div className="notary-bid-message mb-3 d-flex align-items-center">
              <img src={biddingIcon} />
              <span className="ms-3">You are selected to bid on these orders due to your proximity. Please respond promptly, as other notaries have been contacted.</span>
            </div>
          </MobileView>
          <Row>
            {notaryBidsError && <Alert color="danger" className="fade show text-center">
              <i className="mdi mdi-alert-circle-outline me-2"></i>Unable to load leads
            </Alert>}
            {isLoadInProgress && <SpinnerChase className="mx-auto" />}
            {!isLoadInProgress && !notaryBidsError && notaryBids?.length === 0 && <Col>No leads found</Col>}
            {notaryBids?.map(notaryBid => (
              <Col key={notaryBid.id} xs={12} md={6} xl={4}>
                <NotaryBidCard notaryBid={notaryBid} isDetailed={false}>
                  <Button color={notaryBid.status === NotaryBid.STATUS_ACCEPTED_BY_NOTARY ? "warning" : "primary"} className="mt-auto w-100" onClick={() => navigate(route(routes.view_notary_bid, notaryBid.id))}>View Details</Button>
                </NotaryBidCard>
              </Col>
            ))}
            {pageCount > 1 && <Pagination className="pagination pagination-rounded justify-content-center mb-2">
              <div className="pagination-parent">
                <PaginationItem disabled={page === 1}>
                  <PaginationLink
                    previous
                    href=""
                    onClick={handlePageClick(page - 1)}
                  />
                </PaginationItem>
                {map(Array(pageCount), (_item, i) => (
                  <PaginationItem active={i + 1 === page} key={i}>
                    <PaginationLink
                      onClick={handlePageClick(i + 1)}
                      href=""
                    >
                      {i + 1}
                    </PaginationLink>
                  </PaginationItem>
                ))}
                <PaginationItem disabled={page === pageCount}>
                  <PaginationLink
                    next
                    href=""
                    onClick={handlePageClick(page + 1)}
                  />
                </PaginationItem>
              </div>
            </Pagination>}
          </Row>
        </Container>
      </div>
    </React.Fragment>}
    {iAmNotGranted(perms.view_notary_bids) && <AccessDenied />}
  </React.Fragment>
}

export default NotaryBidList;
