import React, { useEffect, useState } from "react";
import { Button } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { showError, showSuccess } from "helpers/utilHelper";
import { getOrder } from "store/actions";
import { docsReceived, getOrderShipping } from "helpers/backendHelper";
import OrderNotary from "model/orderNotary";
import { useProfile } from 'context/profile';
import Order from "model/order";
import { formats, formatDate } from "helpers/dateHelper";
import TrackingModal from "components/Shared/TrackingModal";
import InfoMessage from "components/Shared/InfoMessage";
import StepDocsDeliveredDesktop from "pages/Order/Partial/Flow/Step/Desktop/DocsDelivered";
import StepDocsDeliveredMobile from "pages/Order/Partial/Flow/Step/Mobile/DocsDelivered";
import { BrowserView, MobileView } from "react-device-detect";

// receives shipping data and transforms it into an array of objects containing date, description and address
const normalizeTrackingData = (shippingCompany, data) => {
  if (shippingCompany === Order.SHIPPING_COMPANY_FEDEX) {
    const events = data.map(event => {
      const address = [];
      const { city, stateOrProvinceCode, postalCode } = event.scanLocation;
      // build the address based on the available information
      // since the response may not contain all the address details
      if (city) address.push(city);
      if (stateOrProvinceCode) address.push(stateOrProvinceCode);
      if (postalCode) address.push(postalCode);
      // format the date that is of type ISO string
      const date = formatDate(event.date, formats.TRACKING_DATE);
      return {
        date,
        description: event.eventDescription,
        address,
      }
    })
    return events;
  }
  if (shippingCompany === Order.SHIPPING_COMPANY_UPS) {
    const events = data.map(event => {
      const address = [];
      const { city, stateProvince, postalCode } = event.location?.address || {};
      // build the address based on the available information
      // since the response may not contain all the address details
      if (city) address.push(city);
      if (stateProvince) address.push(stateProvince);
      if (postalCode) address.push(postalCode);
      // format the date that is of type YYYYMMDD
      const date = formatDate(event.date, formats.MEDIUM_DATE);
      return {
        date,
        description: event.status?.description,
        address,
      }
    })
    return events;
  }
  return data;
}

const StepDocsDelivered = () => {

  /********** STATE **********/

  const [isShippingModalOpen, setIsShippingModalOpen] = useState(false);
  // Data for shippingModal
  const [shippingData, setShippingData] = useState(null);
  const [shippingDataError, setShippingDataError] = useState(false);

  let { id } = useParams();
  id = parseInt(id);

  const { notary } = useProfile();

  // redux hook that dispatches actions
  const dispatch = useDispatch();

  const order = useSelector(state => state.Order.Single.order);

  const isDeliveryOptionUpload = order?.docDeliveryOption === Order.DOC_DELIVERY_OPTION_UPLOAD;

  const isNotCurrentStep = !(order.orderNotaryStatus === OrderNotary.STATUS_ASSIGNED);

  const isStepComplete = order.orderNotaryStatus >= OrderNotary.STATUS_DOCS_RECEIVED;

  /********** EFFECTS **********/
  // Shipping
  useEffect(() => {
    if (order.shippingPackageAwb) {
      getShippingData();
    }
  }, []);

  /********** EVENTS **********/
  const toggleShippingModal = () => setIsShippingModalOpen(!isShippingModalOpen);

  /********** OTHERS **********/
  const getShippingData = () => {
    getOrderShipping(order.id)
      .then(response => {
        // the shipping response object is different for UPS/Fedex, we need to bring it to a standard form
        const normalizedData = normalizeTrackingData(order.shippingCompany, response.response.events);
        setShippingData(normalizedData);
      })
      .catch(err => setShippingDataError(true));
  };

  const handleDocsReceived = () => {
    docsReceived(order.orderNotaryId)
      .then(() => {
        showSuccess("Step completed");
        refreshOrder();
      })
      .catch(() => {
        showError("Unable to complete step");
      });
  };

  const refreshOrder = () => dispatch(getOrder(id));

  const isReadOnly = () => order.status == Order.STATUS_CANCELLED || order.orderNotaryStatus == OrderNotary.STATUS_ORDER_COMPLETE;

  return (
    <React.Fragment>
      {!!isDeliveryOptionUpload && <InfoMessage description={OrderNotary.MESSAGE_DELIVERY_OPTION_UPLOAD} />}

      <BrowserView>
        <StepDocsDeliveredDesktop id={id}
          notary={notary}
          order={order}
          isDeliveryOptionUpload={isDeliveryOptionUpload}
          isNotCurrentStep={isNotCurrentStep}
          handleSetShippingModal={() => setIsShippingModalOpen(true)}
        />
      </BrowserView>

      <MobileView>
        <StepDocsDeliveredMobile id={id}
          notary={notary}
          order={order}
          isDeliveryOptionUpload={isDeliveryOptionUpload}
          isNotCurrentStep={isNotCurrentStep}
          handleSetShippingModal={() => setIsShippingModalOpen(true)}
        />
      </MobileView>


      {!isDeliveryOptionUpload && !isStepComplete && <div className="text-end">
        <Button color="primary" className="mav-card-action-btn" onClick={handleDocsReceived} disabled={order.orderNotaryStatus >= OrderNotary.STATUS_DOCS_RECEIVED || isReadOnly()}>Documents received</Button>
      </div>}

      {/* Shipping Modal */}
      <TrackingModal
        isOpen={isShippingModalOpen}
        toggle={toggleShippingModal}
        modalData={shippingData}
        modalDataError={shippingDataError}
        closeModal={() => setIsShippingModalOpen(false)}
        trackingNumber={order?.shippingPackageAwb}
      />
    </React.Fragment>
  )
};

export default StepDocsDelivered;