import React from "react";
import PropTypes from "prop-types";
import PerfectScrollbar from "react-perfect-scrollbar";
import UserAvatar from "components/Shared/UserAvatar";
import { Link } from "react-router-dom";
import { getInitialsFromName } from "helpers/utilHelper";
import { formatTimestamp, getMessageDateFormat } from "helpers/dateHelper";
import { route, routes } from "helpers/routeHelper";
import useAllowedChannels from "hooks/allowedChannels";
import { Col } from "reactstrap";
import UserHeader from "./UserHeader";
import Message from "model/message";
import { UncontrolledTooltip } from "reactstrap";
import { BrowserView, MobileView } from "react-device-detect";

const Sidebar = ({ id, channelId, messages, unreadMessagesCount }) => {

  const channels = useAllowedChannels();

  return (
    <Col xs={12} sm={12} md={12} lg={4} xl={4}>
      <div className="me-lg-4      ">
        {/* Desktop Content */}
        <BrowserView className="h-100">
          <div className="py-2 border-bottom">
            <UserHeader />
          </div>
        </BrowserView>
        <div className="chat-leftsidebar-nav">
          <div className="py-4">
            <h5 className="font-size-14 mb-3 pb-2"><strong>Conversations</strong></h5>
            <ul className="list-unstyled chat-list" id="recent-list">
              <PerfectScrollbar>
                {Object.keys(channels).map(chId => {
                  const channel = channels[chId];
                  const channelMessages = messages[chId];
                  const channelUnreadMessagesCount = unreadMessagesCount[chId];
                  const lastMessage = channelMessages?.length ? channelMessages[channelMessages.length - 1] : ''

                  return (
                    <li
                      key={chId}
                      className={
                        channelId == chId
                          ? "active"
                          : ""
                      }
                    >
                      <BrowserView>
                        <Link to={route(routes.view_order_messages, [id, chId])}>
                          <div className="d-flex">
                            <div className="align-self-center me-3">
                              <UserAvatar id={+chId} initials={getInitialsFromName(channel)} size="sm" />
                            </div>

                            <div className="flex-grow-1 overflow-hidden my-auto">
                              <h5 className="text-truncate font-size-14 mb-1 d-flex align-items-center">
                                {channel}
                                {!!channelUnreadMessagesCount && chId != channelId && <><span id={`unreadMessages${id}`} className={`badge rounded-pill bg-danger ms-2`}>{channelUnreadMessagesCount}</span><UncontrolledTooltip placement="top" target={`unreadMessages${id}`}>{channelUnreadMessagesCount === 1 ? 'New message' : 'New messages'}</UncontrolledTooltip></>}
                              </h5>
                              {lastMessage && <div className="text-truncate">
                                {lastMessage.contentType === Message.CONTENT_TYPE_TEXT && lastMessage.content}
                                {lastMessage.contentType === Message.CONTENT_TYPE_IMAGE && <div className="d-flex">
                                  <i className="mdi mdi-camera me-1" /> <span className="font-size-12">Image</span>
                                </div>}
                              </div>}
                            </div>
                            {lastMessage && <div className="font-size-11">
                              {formatTimestamp(lastMessage.createdTs, getMessageDateFormat(lastMessage.createdTs))}
                            </div>}
                          </div>
                        </Link>
                      </BrowserView>
                      <MobileView>
                        <Link className="bg-white mt-1" to={route(routes.view_order_channel, [id, chId])}>
                          <div className="d-flex">
                            <div className="align-self-center me-3">
                              <UserAvatar id={+chId} initials={getInitialsFromName(channel)} size="md" />
                            </div>

                            <div className="flex-grow-1 overflow-hidden my-auto">
                              <h5 className="text-truncate font-size-13 mb-1 d-flex align-items-center">
                                {channel}
                                {!!channelUnreadMessagesCount && chId != channelId && <><span id={`unreadMessages${id}`} className={`badge rounded-pill bg-danger ms-2`}>{channelUnreadMessagesCount}</span><UncontrolledTooltip placement="top" target={`unreadMessages${id}`}>{channelUnreadMessagesCount === 1 ? 'New message' : 'New messages'}</UncontrolledTooltip></>}
                              </h5>
                              <div className="message-parent font-size-10">
                                <div className="sender-message-name ">{lastMessage.senderName}</div>
                                {lastMessage && <div className="text-truncate mx-3 message-card-mobile">
                                  {lastMessage.contentType === Message.CONTENT_TYPE_TEXT && lastMessage.content}
                                  {lastMessage.contentType === Message.CONTENT_TYPE_IMAGE && <div className="d-flex">
                                    <i className="mdi mdi-camera me-1" /> <span className="font-size-12">Image</span>
                                  </div>}
                                </div>}
                              </div>
                            </div>
                          </div>
                        </Link>
                      </MobileView>
                    </li>
                  )
                })}
              </PerfectScrollbar>
            </ul>
          </div>
        </div>
      </div>
    </Col>
  )
}

Sidebar.propTypes = {
  id: PropTypes.number,
  channelId: PropTypes.number,
  messages: PropTypes.object,
  unreadMessagesCount: PropTypes.object,
}

export default Sidebar;