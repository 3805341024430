import React, { useState } from "react";
import { Row, Col, Button } from "reactstrap";
import PropTypes from "prop-types";
import { formatTimestamp, formats } from "helpers/dateHelper";
import classnames from "classnames";

const OrderInfoSection = ({ order }) => {

  /********** STATE **********/
  const [isExpanded, setIsExpanded] = useState(false);

  /********** OTHER **********/

  const hasAdditionalVehicles = order?.vehicles?.length > 1;

  const canExpand = hasAdditionalVehicles && !isExpanded;

  const canCollapse = hasAdditionalVehicles && isExpanded;

  /********** HANDLERS **********/

  const toggleExpanded = () => setIsExpanded(current => !current);

  return (<React.Fragment>
    <div className="d-flex align-items-center mb-1">
      <i className="bx bx-car font-size-20" />
      <h6 className="ms-2 mb-0">Vehicle Information:</h6>
    </div>

    <Row>
      <Col xs={12} lg={4} className="mb-1">
        <span className="me-2">V.I.N. #:</span>
        <strong>{order.vehicles[0].vin}</strong>
      </Col>
      <Col xs={12} lg={4} className="mb-1">
        <span className="me-2">Vehicle Year, Make, Model:</span>
        <strong>{order.vehicles[0].yearMakeModel || "--"}</strong>
      </Col>
    </Row>

    {canExpand && <Button type="button" color="link" size="sm" className="ps-0" onClick={toggleExpanded}><i className="mdi mdi-chevron-down me-1" />View more</Button>}
    {canCollapse && <Button type="button" color="link" size="sm" className="ps-0" onClick={toggleExpanded}><i className="mdi mdi-chevron-down me-1" />View less</Button>}

    {isExpanded && (
      <div className="py-2">
        <h6>Additional Vehicles</h6>
        {order.vehicles.slice(1).map((vehicle, i) => (
          <div key={i} className={classnames("p-2", { "bg-primary bg-soft": i % 2 === 0 })}>
            <h6 className="mb-2">Vehicle {i + 2}</h6>
            <Row>
              <Col xs={12} lg={4} className="mb-1">
                <span className="me-2">V.I.N. #:</span>
                <strong>{vehicle.vin}</strong>
              </Col>
              <Col xs={12} lg={4} className="mb-1">
                <span className="me-2">Vehicle Year, Make, Model:</span>
                <strong>{vehicle.yearMakeModel || "--"}</strong>
              </Col>
            </Row>
          </div>
        ))}
      </div>
    )}

    <div className="divider-faded divider-sm" />

    <div className="d-flex align-items-center mb-1">
      <i className="bx bx-notepad font-size-20" />
      <h6 className="ms-2 mb-0">Order Information:</h6>
    </div>

    <Row>
      <Col xs={12} lg={3} className="mb-1">
        <span className="me-2">Order ID:</span>
        <strong>{order.id}</strong>
      </Col>
      <Col xs={12} lg={3} className="mb-1">
        <span className="me-2">Scheduler:</span>
        <strong>{order.schedulerFullName}</strong>
      </Col>
    </Row>

    <Row>
      <Col xs={12} lg={3} className="mb-1">
        <span className="me-2">Dealership Name:</span>
        <strong>{order.dealerStoreName || '--'}</strong>
      </Col>
      <Col xs={12} lg={3} className="mb-1">
        <span className="me-2">Scheduler Phone:</span>
        <strong>{order.schedulerPhone || '--'}</strong>
        <div>
          <span className="me-2">Email:</span>
          <strong>{order.schedulerEmail || '--'}</strong>
        </div>
      </Col>
    </Row >

    <Row>
      <Col xs={12} lg={3} className="mb-1">
        <span className="me-2">Date Ordered:</span>
        <strong>{formatTimestamp(order.createdTs, formats.DATETIME)}</strong>
      </Col>
    </Row>
  </React.Fragment >)
}

OrderInfoSection.propTypes = {
  order: PropTypes.object,
};

export default OrderInfoSection;