import React from "react";
import { Button } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { prepareSigning } from "helpers/backendHelper";
import { getOrder } from "store/actions";
import { newLineTextFormat, showError, showSuccess } from "helpers/utilHelper";
import OrderNotary from "model/orderNotary";
import specialInstructionsIcon from "assets/images/special-instructions-icon.svg";
import Order from "model/order";
import { BrowserView, MobileView } from "react-device-detect";
import DealerGroup from "model/dealerGroup";
import InfoMessage from "components/Shared/InfoMessage";
import Instructions from "./Desktop/BeforeSigning/Instructions";
import InstructionsAccordeon from "components/Shared/InstructionsAccorderon";
import dealerChecklistIcon from "assets/images/instructions/dealer-checklist.svg";
import brieflyReviewIcon from "assets/images/instructions/briefly-review.svg";
import downloadDocumentsIcon from "assets/images/instructions/download-documents.svg";
import documentsAfterPrinting from "assets/images/instructions/documents-after-printing.svg";
import documentsIcon from "assets/images/instructions/documents.svg";
import TemplateSectionDesktop from "./Desktop/BeforeSigning/Templates";
import TemplateSectionMobile from "./Mobile/BeforeSigning/Templates";
import DocumentExpectedSectionDesktop from "./Desktop/BeforeSigning/DocumentExpected";
import DocumentExpectedSectionMobile from "./Mobile/BeforeSigning/DocumentExpected";
import PrintAllDocs from "components/Shared/PrintAllDocs";

const instructions = [{
  image: documentsIcon,
  description: 'Prepare the 2 copies of the Affidavit of ID according to these documents.'
},
{
  image: dealerChecklistIcon,
  description: 'Read over and sign the Dealer Offsite Checklist, if included with docs.'
},
{
  image: brieflyReviewIcon,
  description: 'Briefly review the documents for any issues.'
},
{
  image: downloadDocumentsIcon,
  description: 'Download the documents below to print.'
},
{
  image: documentsAfterPrinting,
  description: 'Please make sure you take the documents with you after printing.'
},
];

const StepBeforeSigning = () => {

  let { id } = useParams();
  id = parseInt(id);

  // redux hook that dispatches actions
  const dispatch = useDispatch();

  const order = useSelector(state => state.Order.Single.order);
  const { docs: inkSignDocs } = useSelector(state => state.Order.InkSignDocs);

  const aoiDoc = inkSignDocs?.filter(doc => doc.name === 'AOI');
  const notaryChecklistDoc = inkSignDocs?.filter(doc => doc.name === 'Notary Checklist');

  const isDeliveryOptionUploaded = order?.docDeliveryOption === Order.DOC_DELIVERY_OPTION_UPLOAD;
  const isDeliveryOptionShipping = order?.docDeliveryOption === Order.DOC_DELIVERY_OPTION_SHIPPING;
  const mustPreviewDocs = order?.notaryMustUploadDocs;

  const downloadBtnClassName = `btn document-download pb-0 ${isDeliveryOptionUploaded || isDeliveryOptionShipping ? '' : 'no-event'}`;

  const canBeDownload = () => !isDeliveryOptionShipping || isDeliveryOptionUploaded;

  const isStepComplete = order.orderNotaryStatus >= OrderNotary.STATUS_DOCS_PRINTED;

  // Split the text into separate lines
  let lines = order?.dealerGroupSpecialInstructions?.split('\n');

  const isGroupSpecialInstructionsAppliesTo = () => order?.dealerGroupSpecialInstructionsAppliesTo?.some(item => item == DealerGroup.SPECIAL_INTSTRUCTIONS_APPLY_TO_NOTARY);
  const showGroupSpecialInstructions = () => order?.dealerGroupSpecialInstructions && isGroupSpecialInstructionsAppliesTo();

  const confirm = () => {
    prepareSigning(order.orderNotaryId)
      .then(() => {
        showSuccess("Step completed");
        refreshOrder();
      })
      .catch(() => {
        showError("Unable to complete step");
      });
  };

  const refreshOrder = () => dispatch(getOrder(id));

  const isReadOnly = () => order.status == Order.STATUS_CANCELLED || order.orderNotaryStatus == OrderNotary.STATUS_ORDER_COMPLETE;

  return (
    <React.Fragment>
      {!!mustPreviewDocs && <InfoMessage description={OrderNotary.MESSAGE_MUST_PREVIEW_DOCS} />}

      {/* Desktop Instructions */}
      <BrowserView>
        <Instructions dealerInstructions={order.dealerInstructions} />
      </BrowserView>
      {/* Mobile Instructions */}
      <MobileView className="mb-3">
        <InstructionsAccordeon instructions={instructions} />
      </MobileView>

      {order.additionalItemsToCollect && <>
        <h6>Also the dealership asked you to collect:</h6>
        <p>{order.additionalItemsToCollect}</p>
        <div className="divider-faded divider-sm" />
      </>}

      {showGroupSpecialInstructions() &&
        <div className="group-special-instructions">
          <div className='d-flex align-items-center mb-3'>
            <img src={specialInstructionsIcon} alt='special-instructions' />
            <h6 className='mb-0 ms-2'>Group Special Instructions</h6>
          </div>
          <div className="description ps-2">
            {newLineTextFormat(lines).map((line, index) => <p className='mb-1' key={index}>{line}</p>)}
          </div>
        </div>
      }

      {/* Desktop Templates section */}
      <BrowserView>
        <TemplateSectionDesktop order={order}
          downloadBtnClassName={downloadBtnClassName}
          notaryChecklistDoc={notaryChecklistDoc}
          aoiDoc={aoiDoc}
          isDeliveryOptionUploaded={isDeliveryOptionUploaded}
          isDeliveryOptionShipping={isDeliveryOptionShipping}
        />
        <DocumentExpectedSectionDesktop order={order}
          inkSignDocs={inkSignDocs}
          isDeliveryOptionUploaded={isDeliveryOptionUploaded}
          downloadBtnClassName={downloadBtnClassName}
          canBeDownload={canBeDownload}
        />
      </BrowserView>

      {/* Mobile Templates section */}
      <MobileView>
        <DocumentExpectedSectionMobile order={order}
          inkSignDocs={inkSignDocs}
          isDeliveryOptionUploaded={isDeliveryOptionUploaded}
          downloadBtnClassName={downloadBtnClassName}
          canBeDownload={canBeDownload}
        />
        <TemplateSectionMobile order={order}
          downloadBtnClassName={downloadBtnClassName}
          notaryChecklistDoc={notaryChecklistDoc}
          aoiDoc={aoiDoc}
          isDeliveryOptionUploaded={isDeliveryOptionUploaded}
          isDeliveryOptionShipping={isDeliveryOptionShipping}
        />
      </MobileView>

      <PrintAllDocs id={order.id} />
      {!isStepComplete &&
        <div className="text-end">
          <Button color="primary" className="mav-card-action-btn" onClick={confirm} disabled={order.orderNotaryStatus !== OrderNotary.STATUS_APPOINTMENT_ACCEPTED || isReadOnly()}>Confirm</Button>
        </div>
      }
    </React.Fragment >
  );
}

export default StepBeforeSigning;