import React, { useState } from 'react';
import MetaTitle from 'components/Shared/MetaTitle';
import { Container, Row, Col } from 'reactstrap';
import Breadcrumbs from "components/Common/Breadcrumb2";
import MyProfile from './Section/MyProfile';
import { BrowserView, MobileView } from "react-device-detect";
import Documents from "./Section/Documents";
import Statistics from "./Section/Statistics";
import { useAuth } from "context/auth"
import ChatsMobile from "./Section/Mobile/Chats";
import ChatsDesktop from "./Section/Desktop/Chats";
import WIPOrdersDesktop from "./Section/Desktop/WIPOrders";
import WIPOrdersMobile from "./Section/Mobile/WIPOrders";
import BounceEmailAlert from "./Section/BounceEmailAlert";

const Dashboard = () => {

  const { user: authUser } = useAuth();

  return <React.Fragment>
    <div className="page-content">
      <MetaTitle>Dashboard</MetaTitle>
      <Container fluid className='dashboard'>
        <Breadcrumbs title="DASHBOARD" />
        {!!authUser.hasEmailAlert && <BounceEmailAlert user={authUser} />}
        <Row className="d-flex align-items-stretch mb-xl-4 mb-xs-2">
          <Col xs={12} xl={3} className="mb-4 mb-xl-0"><MyProfile /></Col>
          <Col xs={12} xl={6} className="mb-4 mb-xl-0"><Statistics /></Col>
          <Col xs={12} xl={3} className="mb-4 mb-xl-0"><Documents /></Col>
        </Row>

        <Row className="d-flex align-items-stretch mb-xl-4 mb-xs-2">
          <BrowserView>
            <Col xs={12} xl={12}><ChatsDesktop /></Col>
          </BrowserView>
          <MobileView>
            <Col xs={12} xl={12}><ChatsMobile /></Col>
          </MobileView>
        </Row>

        <Row className="d-flex align-items-stretch mb-xl-4 mb-xs-2">
          <BrowserView>
            <Col xs={12} xl={12}><WIPOrdersDesktop /></Col>
          </BrowserView>
          <MobileView>
            <Col xs={12} xl={12}><WIPOrdersMobile /></Col>
          </MobileView>
        </Row>
      </Container>
    </div>
  </React.Fragment >
}

export default Dashboard;
